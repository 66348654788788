// extracted by mini-css-extract-plugin
export var footer = "nd";
export var footerBottom = "ud";
export var footerBottom__copyWrapper = "yd";
export var footerBottom__footerLink = "xd";
export var footerBottom__footerSocial = "Ad";
export var footerBottom__left = "vd";
export var footerBottom__links = "wd";
export var footerBottom__textDeveloper = "zd";
export var footerTop = "pd";
export var footerTop__appButton = "sd";
export var footerTop__logo = "td";
export var footerTop__menu = "rd";
export var footerWrapper = "od";
export var withButton = "qd";